<template>
    <div id="gy-container" style="height: 100%;box-sizing: border-box"></div>
</template>

<script>
import {
    Page,
    Survey
} from 'gd-sdk'
export default {
    name: "guanyuanPage",
    data:()=>{
        return {
            pageUrl:''
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        //https://portal.lechun.cc/portal/guanyuanpage?id=1838865296289828864
        initData() {
            this.$apiGet('lechun-baseservice/sso/buildGuanyuanAccessTokenNew',{ssoId:'1838865296289828864'}).then(res=>{
                console.log(res)
                let publicObj = {
                    host: 'https://lechun.guandatacloud.com', // BI 地址
                    domain: res.provider, // BI 公司域
                    ssoToken: res.ssoToken, // BI SSOToken
                }
                // this.pageUrl = `https://lechun.guandatacloud.com/page/ae2982fe932114fe39237e00?domain=${res.provider}&ssoToken=${res.ssoToken}`
                let param = this.setFormParam(res)
                if(res.type=='form'){
                    new Survey(
                        publicObj,
                        {
                            ps: param.ps, // 内嵌参数
                            container: document.getElementById('gy-container'), // 页面渲染容器
                            surveyId: param.id, // form Id，（用于生成 iframe 的 src）
                            query:{}
                        }
                    )
                }else{
                    new Page(
                        publicObj,
                        {
                            ps: param.ps, // 内嵌参数
                            container: document.getElementById('gy-container'), // 页面渲染容器
                            pgId: param.id, // 页面 Id，（用于生成 iframe 的 src）
                            query:{}
                        }
                    )
                }
            })

        },
        setFormParam (param){
            let showTypeObj = {
                0:'iframe2',
                1:'iframe3',
                2:'iframe',
                3:'',
            }
            let showType = showTypeObj[param.showType]||''
            let fullUrl = param.url
            let sid = ''
            if(fullUrl.indexOf('/page')>-1){
                sid = fullUrl.split('/page/')[1]
            }else if(fullUrl.indexOf('/form-data')){
                sid = fullUrl.split('/form-data/')[1]
            }
            return {ps:showType,id:sid}
        }
    }
}
</script>

<style scoped>

</style>